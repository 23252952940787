.rank-3 {
    background-color: #b9983d;
  }
  
  .rank-2 {
    background-color: #d2d1cb;
  }
  
  .rank-1 {
    background-color: #ffea00;
  }
  